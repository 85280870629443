/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:49:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-07 14:19:20
 */
<template>
  <div class="myPractice">
    选择课程
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
  .myPractice{
    
  }
</style>